import React from "react"

const ContactForm = ({
  className,
  includeMessage = false,
  formName = "contact",
}) => {
  return (
    <form
      className={className}
      data-netlify-honeypot="bot-field"
      data-netlify="true"
      name={formName}
      action="/thanks/"
      method="POST"
    >
      <input type="hidden" name="form-name" value={formName} />
      <input name="bot-field" type="hidden" />
      <input name="fullname" type="text" placeholder="Name" required />
      <input name="company" type="text" placeholder="Company" required />
      <input name="email" type="email" placeholder="Email" required />
      <input name="phone" type="tel" placeholder="Phone" />

      {includeMessage && (
        <div>
          <textarea name="message" placeholder="Message"></textarea>
        </div>
      )}

      <button type="submit" className="button">
        Send
      </button>
    </form>
  )
}
export default ContactForm
