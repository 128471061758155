import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import ConsultationCta from "../components/ConsultationCta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Line from "../images/software-line.inline.svg"

/**
 *
 * @param {children} ReactElement an exception for the hero-card
 */
const SoftwareCard = ({ children, title, icon, url, descr }) => (
  <article className="software-card">
    <h2 className="software-card__title">
      <img src={icon.publicURL} alt={title} className="software-card__icon" />

      <span className="software-card__title-text">
        {title}
        {children}
      </span>
    </h2>

    <p>{descr}</p>

    {/* <Link to={url} className="link link--arrow-right software-card__link">
      Learn more
    </Link> */}
  </article>
)

export const query = graphql`
  query {
    allSoftwareYaml(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          slug
          title
          description
          icon {
            publicURL
          }
        }
      }
    }

    Hero: file(relativePath: { eq: "software-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Software = ({ data }) => {
  return (
    <Layout>
      <SEO title="Software" />

      <div className="container">
        <header className="header grid software-hero">
          <div className="software-hero-content">
            <h1 className="software-hero__title">
              Our services plus our software power up your workflows
            </h1>
            <p className="h4">
              Upright Labs Lister is the best multichannel listing and inventory
              management tool out there. Used by some of the top secondhand
              resellers in the world, Lister has helped many organizations list
              and manage their inventory online.
            </p>

            <Link to="/contact" className="button software-hero__button">
              Get a demo
            </Link>

            <SoftwareCard
              title={data.allSoftwareYaml.edges[0].node.title}
              url={`/software/${data.allSoftwareYaml.edges[0].node.slug}`}
              icon={data.allSoftwareYaml.edges[0].node.icon}
              descr={data.allSoftwareYaml.edges[0].node.description}
            >
              <Line className="software-hero__line" />
            </SoftwareCard>
          </div>

          <div className="software-hero-img">
            <figure className="figure-gradient">
              <Img
                fluid={data.Hero.childImageSharp.fluid}
                alt="Dashboard screenshot"
                className="software-hero__img"
              />
            </figure>
          </div>
        </header>

        <div className="software-cards">
          {data.allSoftwareYaml.edges
            .slice(1, data.allSoftwareYaml.edges.length)
            .map(({ node }, index) => {
              return (
                <SoftwareCard
                  key={index}
                  title={node.title}
                  descr={node.description}
                  url={`/software/${node.slug}`}
                  icon={node.icon}
                />
              )
            })}
        </div>
      </div>

      <ConsultationCta />
    </Layout>
  )
}

export default Software
