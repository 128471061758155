import React from "react"
import ContactForm from "../components/ContactForm"
import Plane from "../images/plane.inline.svg"

const ConsulationCta = () => {
  return (
    <div className="consultation-cta">
      <div className="consultation-cta__container container">
        <Plane className="consultation-cta__icon" />

        <h1 className="consultation-cta__title">
          Schedule a complimentary 30-minute consultation
        </h1>

        <ContactForm className="consultation-cta__form"></ContactForm>
      </div>
    </div>
  )
}

export default ConsulationCta
